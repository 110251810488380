import React from "react"
import { BsArrowsFullscreen } from "@react-icons/all-files/bs/BsArrowsFullscreen"
import Modal from "react-bootstrap/Modal"
import { useState } from "react"


export default function Kitchen() {
  let [url, setUrl] = useState("/images/portfolio/gallery/g-skaterboy.jpg")

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // useEffect(() => {
  //   setModal(new Modal(exampleModal.current))
  // }, [])
  return (
    <section>
      <section id="kitchen" className="portfolio">
        <div className="row narrow section-intro with-bottom-sep">
          <div className="col-twelve">
            <h1 className="animate-this">Kitchen</h1>
            <h3 className="animate-this">
              See My Latest Projects In Kitchen.
            </h3>

            {/* <p className="lead animate-this">
              Lorem ipsum Dolor adipisicing nostrud et aute Excepteur amet
              commodo ea dolore irure esse Duis nulla sint fugiat cillum ullamco
              proident aliquip quis qui voluptate dolore veniam Ut laborum non
              est in officia.
            </p> */}
          </div>
        </div>

        <div className="row portfolio-content">
          <div className="col-twelve">
            <div id="folio-wrap" className="bricks-wrapper">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(el => (
                <div
                  className="brick folio-item"
                  onClick={() => {
                    setUrl(`/images/portfolio/kitchen/img-${el}.jpg`)

                    // modal.show()
                    handleShow()
                  }}
                >
                  <div className="item-wrap animate-this" data-sub-html="#01">
                    <div className="overlay">
                      <span className="icon">
                        <BsArrowsFullscreen />
                      </span>
                      <div className="image">
                        <img
                          src={`/images/portfolio/kitchen/img-${el}.jpg`}
                          alt="Skaterboy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div
        className="modal fade "
        ref={exampleModal}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">

              <div></div>
            </div>
          </div>
        </div>
      </div> */}

        <Modal size="xl" show={show} onHide={handleClose} animation={true}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img className="image" src={url} alt="Skaterboy" />
          </Modal.Body>
        </Modal>
      </section>
      {/* <Backsplash /> */}
    </section>
  )
}
